import axios from "axios"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

import CustomButton from "@/_components/CustomButton"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { downloadFileFromUrl } from "@/_services/utils"

const camt = {
  // <EntitiesPage/>
  hideAddButton: true,
  columns: [
    { title: "Registration", name: "registration", linkTo: "/camt/{registration}" },
    { title: "Organization", name: "organization.name", hidden: true },
    { title: "Status", name: "status", select: "camtStatus", badge: true },
    { title: "Method", name: "method", select: "camtMethod" },
    { title: "File date", name: "fileDate", type: "datetime" },
    { title: "File", name: "file" },
  ],
  searchFields: getSearchFields(),
  formatTableData,
  cardFooter,
}

function cardFooter({ component }) {
  const handleCamtUpload = async ({ event, component }) => {
    const files = Array.from(event.target.files)
    if (!files || files.length === 0) return

    const formData = new FormData()
    formData.append("file", files[0])

    event.target.value = null
    component.setState({ camtLoading: true })
    try {
      await axios.post("/api/billing/camt/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
      addNotification(`File uploaded`)
    } catch (error) {
      addOops(error)
    }
    component.componentGetEntities()
    component.setState({ camtLoading: false })
  }

  return (
    <Row className="mt-theme">
      <Col xs={12}>
        <CustomButton
          bsStyle="primary"
          bsSize="small"
          pullRight
          loading={component.state.camtLoading}
          className="inline-flex align-items-center"
          image="icn-money-check icn-sm mr-5px"
          label={loc`Import CAMT`}
          onClick={() => component["upload_"].click()}
        />
        <input
          type="file"
          id="single"
          ref={ref => (component["upload_"] = ref)}
          onChange={event => handleCamtUpload({ event, component })}
          style={{ display: "none" }}
        />
      </Col>
    </Row>
  )
}

function formatTableData(data) {
  const handleFileDownload = async ({ event, camt }) => {
    event.preventDefault()
    event.stopPropagation()
    await downloadFileFromUrl(`/api/billing/camts/${camt.registration}/content`, "CAMT file(s) downloaded", "Downloading CAMT")
  }

  return data.map(camt => ({
    ...camt,
    ...(camt.bankAccount || {}),
    file: (
      <Link to="" onClick={event => handleFileDownload({ event, camt })}>
        <i className="icn-file-download icn-xs" title={loc`Download file`} />
      </Link>
    ),
  }))
}

function getSearchFields() {
  return [
    [
      { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
      { field: "status", type: "multiple", select: "camtStatus", colProps: { xs: 12, sm: 4 } },
      { field: "method", select: "camtMethod", colProps: { xs: 12, sm: 4 } },
    ],
  ]
}

export default camt
