import { genericErrors } from "./errorUtils.mjs"
import { defaultLocaleCode, getLocaleParameters } from "./localeUtils.mjs"
import { extractNumberFromString, padNumber } from "./numberUtils.mjs"

// https://github.com/mobz/get-timezone-offset
function parseDate(dateStr) {
  dateStr = dateStr.replace(/[\u200E\u200F]/g, "")
  return [].slice.call(/(\d+).(\d+).(\d+),?\s+(\d+).(\d+)(.(\d+))?/.exec(dateStr), 1).map(Math.floor)
}

function diffMinutes(d1, d2) {
  let day = d1[1] - d2[1]
  let hour = d1[3] - d2[3]
  let min = d1[4] - d2[4]

  if (day > 15) day = -1
  if (day < -15) day = 1

  return 60 * (24 * day + hour) + min
}

function getTimezoneOffset(timeZone, date) {
  const locale = "en-US"
  const formatOptions = {
    timeZone: "UTC",
    hourCycle: "h23",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
  const utcFormat = new Intl.DateTimeFormat(locale, formatOptions)
  const localFormat = new Intl.DateTimeFormat(locale, { ...formatOptions, timeZone })

  return diffMinutes(parseDate(utcFormat.format(date)), parseDate(localFormat.format(date)))
}

/**
 * @param {date} date
 * @param {boolean} withTime to disable the time stripping
 * @returns Returns a date object at the same date as given but stripped of the time part, e.g. set it to 00:00 in UTC timezone (with time zone offet if time zone specified).
 */
function dateNoTime(dateOrYear, withTimeOrMonth, day) {
  if (!dateOrYear) return dateOrYear
  let month
  if (typeof withTimeOrMonth === "number") month = withTimeOrMonth
  else if (withTimeOrMonth === true && typeof dateOrYear === "object") return dateOrYear
  let noTimeDate
  if (typeof dateOrYear === "object") {
    noTimeDate = new Date(Date.UTC(dateOrYear.getFullYear(), dateOrYear.getMonth(), dateOrYear.getDate()))
  } else {
    noTimeDate = new Date(Date.UTC(dateOrYear, month, day))
  }
  return noTimeDate
}

/**
 * @param {number} milliseconds
 * @returns The milliseconds converted to a string formatted like "10h 03m 55s".
 */
function formatMilliseconds(milliseconds) {
  if (!milliseconds) return milliseconds

  if (milliseconds < 1000) return `${milliseconds}ms`

  const seconds = milliseconds / 1000
  if (seconds < 60) return `${seconds.toFixed(0)}s` // 4s

  const minutes = Math.floor(seconds / 60)
  if (minutes > 60) {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = Math.floor(minutes % 60)

    const remainingSeconds = Math.floor(seconds % 60)
    if (remainingSeconds) return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`

    return `${hours}h ${remainingMinutes}mins`
  }

  const remainingSeconds = Math.floor(seconds % 60)
  if (remainingSeconds) return `${minutes}m ${remainingSeconds}s` // 5m 3s
  return `${minutes}min${minutes > 1 ? "s" : ""}` // 5mins
}

/**
 * @param {date} date
 * @returns Returns the given date set to the last day of its month.
 */
function dateLastDayOfMonth(date, monthsToAdd = 0, withTime) {
  if (!date) return date
  return dateNoTime(new Date(date.getFullYear(), date.getMonth() + monthsToAdd + 1, 0), withTime)
}

/**
 * @param {date} date
 * @returns Returns the given date set to the first day of its month.
 */
function dateFirstDayOfMonth(date, monthsToAdd = 0, withTime) {
  if (!date) return date
  return dateNoTime(new Date(date.getFullYear(), date.getMonth() + monthsToAdd, 1), withTime)
}

function dateIsLastDayOfMonth(date, withTime) {
  const prevMonth = date.getMonth()
  const month = dateAdd(date, "1d", withTime).getMonth()
  // this does not work with 31 December, month is 12 and prevMonth is 0
  // return month === prevMonth + 1
  return month !== prevMonth
}

/**
 * A convenient function to get the duration of a period expressed in a short way like '30d' (30 days).
 * Warning : do not use this function for precise computations as it rounds some periods.
 * Warning : this is not the same as periods expressed in the package jsonwebtoken, which uses vercel/ms under the hood.
 * In jsonwebtoken "s" means seconds and "m" minutes where as here they mean semesters and months.
 * @param {string} period
 * @param {object} options
 * @param {boolean} options.useJwtFormat // Set to true to interpret "s" as second and "m" as minute.
 * @returns An object with properties named after the period expressed.
 */
function parsePeriod(period, options) {
  if (!period) return
  if (typeof period === "object") {
    const periodObj = {}
    Object.keys(period).forEach(key => {
      periodObj[key] = Number(period[key])
    })
    return periodObj
  }
  if (typeof period !== "string") return period

  // "months=1,day=1": beginning of next month
  // "years=1,months=1,day=1": beginning of next year
  if (period.includes(",")) {
    const periodObj = {}
    for (const part of period.split(",")) {
      if (!part.includes("=")) continue
      const [key, value] = part.split("=")
      periodObj[key] = Number(value) // usually the value is a number
    }
    return periodObj
  }

  // "1d", "1m", etc..
  const periodNumber = extractNumberFromString(period)

  // we use an average number of days in periods (i.e. 1 month = 30 days)
  const secondsInOneDay = 24 * 60 * 60
  const daysInOneMonth = 30
  const daysInOneYear = 365
  const { useJwtFormat } = options || {}
  if (period.endsWith("d")) return { days: periodNumber, seconds: periodNumber * secondsInOneDay }
  if (period.endsWith("m")) {
    return useJwtFormat
      ? { minutes: periodNumber, seconds: periodNumber * 60 }
      : { months: periodNumber, seconds: periodNumber * secondsInOneDay * daysInOneMonth }
  }
  if (period.endsWith("q")) return { quarters: periodNumber, seconds: periodNumber * secondsInOneDay * daysInOneMonth * 3 }
  if (period.endsWith("s")) {
    return useJwtFormat ? { seconds: periodNumber } : { semesters: periodNumber, seconds: periodNumber * secondsInOneDay * daysInOneMonth * 6 }
  }
  if (period.endsWith("y")) return { years: periodNumber, seconds: periodNumber * secondsInOneDay * daysInOneYear }
  if (period.endsWith("h")) return { hours: periodNumber, minutes: periodNumber * 60, seconds: periodNumber * 60 * 60 }
  if (period.endsWith("min")) return { minutes: periodNumber, seconds: periodNumber * 60 }
  if (period.endsWith("sec")) return { seconds: periodNumber }
  throw Error(genericErrors.INVALID_PERIOD)
}

// WARNING: This method ignores the time, it processes only dates
// WARNING: If you need to manipulate dates with time DO NOT use this function
function dateAdd(date, period, withTime = false) {
  if (!date || !period) return date

  const { days, months, quarters = 0, semesters = 0, years, day, month, year } = parsePeriod(period)

  let newDate = new Date(date.getTime()) // Clone date
  // Add years //TODO : bug if February (leap years) => add dateIsLastDayOfMonth + dateLastDayOfMonth
  if (years)
    newDate = new Date(
      newDate.getFullYear() + years,
      newDate.getMonth(),
      newDate.getDate(),
      withTime && newDate.getHours(),
      withTime && newDate.getMinutes(),
      withTime && newDate.getSeconds(),
      withTime && newDate.getMilliseconds(),
    )

  // Add/Subtract semesters
  for (let i = 0; i < Math.abs(semesters); i++) {
    let newMonth = newDate.getMonth()
    let newYear = newDate.getFullYear()

    // Add semesters
    if (semesters > 0) {
      if (newMonth < 6) newMonth = 6 // July
      else {
        newMonth = 0 // January
        newYear++
      }
    }
    // Subtract semesters
    else {
      if (newMonth > 5) newMonth = 0 // January
      else {
        newMonth = 6 // July
        newYear--
      }
    }

    if (dateIsLastDayOfMonth(newDate)) {
      newDate = new Date(
        newYear,
        newMonth,
        dateLastDayOfMonth(new Date(newDate.getFullYear(), newMonth)).getDate(),
        withTime && newDate.getHours(),
        withTime && newDate.getMinutes(),
        withTime && newDate.getSeconds(),
        withTime && newDate.getMilliseconds(),
      )
    } else {
      newDate = new Date(
        newYear,
        newMonth,
        newDate.getDate(),
        withTime && newDate.getHours(),
        withTime && newDate.getMinutes(),
        withTime && newDate.getSeconds(),
        withTime && newDate.getMilliseconds(),
      )
    }
  }

  // Add/Subtract quarters
  for (let i = 0; i < Math.abs(quarters); i++) {
    let newMonth = newDate.getMonth()
    let newYear = newDate.getFullYear()

    // Add quarters
    if (quarters > 0) {
      if (newMonth < 3) newMonth = 3 // April
      else if (newMonth < 6) newMonth = 6 // July
      else if (newMonth < 9) newMonth = 9 // October
      else {
        newMonth = 0 // January
        newYear++
      }
    }
    // Subtract quarters
    else {
      if (newMonth > 8) newMonth = 6 // July
      else if (newMonth > 5) newMonth = 3 // April
      else if (newMonth > 2) newMonth = 0 // January
      else {
        newMonth = 9
        newYear--
      }
    }

    if (dateIsLastDayOfMonth(newDate, withTime)) {
      newDate = new Date(
        newYear,
        newMonth,
        dateLastDayOfMonth(new Date(newDate.getFullYear(), newMonth)).getDate(),
        withTime && newDate.getHours(),
        withTime && newDate.getMinutes(),
        withTime && newDate.getSeconds(),
        withTime && newDate.getMilliseconds(),
      )
    } else {
      newDate = new Date(
        newYear,
        newMonth,
        newDate.getDate(),
        withTime && newDate.getHours(),
        withTime && newDate.getMinutes(),
        withTime && newDate.getSeconds(),
        withTime && newDate.getMilliseconds(),
      )
    }
  }

  // Add months
  if (months) {
    const newMonth = newDate.getMonth() + months

    if (dateIsLastDayOfMonth(newDate, withTime)) {
      newDate = new Date(
        newDate.getFullYear(),
        newMonth,
        dateLastDayOfMonth(new Date(newDate.getFullYear(), newMonth)).getDate(),
        withTime && newDate.getHours(),
        withTime && newDate.getMinutes(),
        withTime && newDate.getSeconds(),
        withTime && newDate.getMilliseconds(),
      )
    } else {
      const expectedDate = new Date(
        newDate.getFullYear(),
        newMonth,
        newDate.getDate(),
        withTime && newDate.getHours(),
        withTime && newDate.getMinutes(),
        withTime && newDate.getSeconds(),
        withTime && newDate.getMilliseconds(),
      )
      const expectedMonth = expectedDate.getMonth()
      const yearsDiff = Math.abs(newDate.getFullYear() - expectedDate.getFullYear())

      let isMonthCorrect
      if (newMonth < 0) isMonthCorrect = expectedMonth === newMonth + 12 * yearsDiff
      else if (!yearsDiff) isMonthCorrect = expectedMonth === newMonth
      else isMonthCorrect = expectedMonth === newMonth % (12 * yearsDiff)

      if (!isMonthCorrect) {
        newDate = new Date(
          newDate.getFullYear(),
          newMonth,
          dateLastDayOfMonth(new Date(newDate.getFullYear(), newMonth)).getDate(),
          withTime && newDate.getHours(),
          withTime && newDate.getMinutes(),
          withTime && newDate.getSeconds(),
          withTime && newDate.getMilliseconds(),
        )
      } else {
        newDate = new Date(
          newDate.getFullYear(),
          newMonth,
          newDate.getDate(),
          withTime && newDate.getHours(),
          withTime && newDate.getMinutes(),
          withTime && newDate.getSeconds(),
          withTime && newDate.getMilliseconds(),
        )
      }
    }
  }

  // Add days
  if (days)
    newDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate() + days,
      withTime && newDate.getHours(),
      withTime && newDate.getMinutes(),
      withTime && newDate.getSeconds(),
      withTime && newDate.getMilliseconds(),
    )

  // Warning!: "!isNaN(null) => true" & "null >= 0 => true"
  if (day !== null && day >= 0)
    newDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      day,
      withTime && newDate.getHours(),
      withTime && newDate.getMinutes(),
      withTime && newDate.getSeconds(),
      withTime && newDate.getMilliseconds(),
    )
  if (month)
    newDate = new Date(
      newDate.getFullYear(),
      month - 1,
      newDate.getDate(),
      withTime && newDate.getHours(),
      withTime && newDate.getMinutes(),
      withTime && newDate.getSeconds(),
      withTime && newDate.getMilliseconds(),
    ) // month is 0 based
  if (year)
    newDate = new Date(
      year,
      newDate.getMonth(),
      newDate.getDate(),
      withTime && newDate.getHours(),
      withTime && newDate.getMinutes(),
      withTime && newDate.getSeconds(),
      withTime && newDate.getMilliseconds(),
    )

  return dateNoTime(newDate, withTime)
}

/**
 * WARNING!! This function is deprecated and will be removed soon (after release 11/04/2023). Use dateAdd(date, { days }) instead
 * @param {date} date
 * @param {number} nbDays
 * @returns Adds the number of days to the given date and return it.
 */
function dateAddDays(date, nbDays) {
  return new Date(date.getTime() + 1000 * 60 * 60 * 24 * nbDays)
}

/**
 * From https://www.epoch-calendar.com/support/getting_iso_week.html
 * @param {date} date
 * @param {string} locale
 * @returns Returns the ISO week number of the given date accounting for the week start day of the locale.
 */
function getWeekNumber(date, locale) {
  const { weekStartDay = 0 } = getLocaleParameters(locale)
  const newYear = new Date(date.getFullYear(), 0, 1)
  let day = newYear.getDay() - weekStartDay // the day of week the year begins on
  day = day >= 0 ? day : day + 7
  const dayNumber = Math.floor((date.getTime() - newYear.getTime() - (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1

  let weekNumber
  // if the year starts before the middle of a week
  if (day < 4) {
    weekNumber = Math.floor((dayNumber + day - 1) / 7) + 1
    if (weekNumber > 52) {
      const nYear = new Date(date.getFullYear() + 1, 0, 1)
      let numberDay = nYear.getDay() - weekStartDay
      numberDay = numberDay >= 0 ? numberDay : numberDay + 7
      // if the next year starts before the middle of the week, it is week #1 of that year
      weekNumber = numberDay < 4 ? 1 : 53
    }
  } else {
    weekNumber = Math.floor((dayNumber + day - 1) / 7)
  }

  return weekNumber
}

/**
 * @param {date} date
 * @param {string} locale
 * @returns Returns an object containing the start and end dates of the week of the given date. Default start day of the week is Sunday (as provided natively by javascript) but is adjusted according to the locale (for the french locale the start day of the week is Monday).
 */
function getWeekPeriod(date, locale) {
  const { weekStartDay = 0 } = getLocaleParameters(locale)
  const firstWeekDay = date.getDate() - date.getDay() + weekStartDay

  return {
    start: new Date(date.setDate(firstWeekDay)),
    // in JS the last week day index is 6, not 7 because the first is 0
    // setting it to +7 would make the last day of the week overlap with the first day of the next one
    end: new Date(date.setDate(date.getDate() + 6)),
  }
}

/**
 * @param {date} date
 * @returns Returns true if the provided argument is a date, false otherwise.
 */
function isValidDate(date) {
  return typeof date?.getMonth === "function"
}

function formatDate(date, locale, option) {
  if (!date) return
  if (!locale) locale = defaultLocaleCode
  if (locale === "en-EU") locale = "en-GB"
  if (locale === "en-LT") locale = "lt-LT"
  if (typeof date === "string") return date
  if (option && "timeZone" in option && !option.timeZone) delete option.timeZone
  return isValidDate(date) ? date.toLocaleDateString(locale, option) : date
}

/**
 * @param {date} date
 * @param {string=""} separator
 * @returns Returns the YYYYMMDD string representation of the date with each date parts separated by the separator.
 */
function YYYYMMDD(date, separator) {
  separator = separator || ""
  if (!date) date = new Date()
  return `${date.getFullYear()}${separator}${padNumber(1 + date.getMonth())}${separator}${padNumber(date.getDate())}`
}

/**
 * @param {date} date
 * @param {string=""} dateSeparator
 * @param {string=""} hourSeparator
 * @returns Returns the YYYYMMDDHHMMSS string representation of the date
 * with each date parts separated by the "dateSeparator"
 * and hour parts separated by the "hourSeparator".
 */
function YYYYMMDDHHMMSS(date, dateSeparator, hourSeparator) {
  return YYYYMMDD(date, dateSeparator) + HHMMSS(date, hourSeparator)
}

/**
 * @param {date} date
 * @param {string=""} dateSeparator
 * @param {string=""} hourSeparator
 * @returns Returns the YYYYMMDDTHHMMSS string representation of the date
 * with each date parts separated by the "dateSeparator"
 * and hour parts separated by the "hourSeparator"
 * and the two parts separated by a "T"
 */
function YYYYMMDDTHHMMSS(date, dateSeparator, hourSeparator, hhmmss) {
  return YYYYMMDD(date, dateSeparator) + "T" + (hhmmss || HHMMSS(date, hourSeparator))
}

/**
 * @param {date} date
 * @param {string=""} separator
 * @returns Returns the YYYYMM string representation of the date with each date parts separated by the separator.
 */
function YYYYMM(date, separator) {
  separator = separator || ""
  if (!date) date = new Date()

  return `${date.getFullYear()}${separator}${padNumber(1 + date.getMonth())}`
}

/**
 * @param {date} date
 * @param {string=""} separator
 * @returns Returns the MMYYYY string representation of the date with each date parts separated by the separator.
 */
function MMYYYY(date, separator) {
  separator = separator || ""
  if (!date) date = new Date()
  return `${padNumber(1 + date.getMonth())}${separator}${date.getFullYear()}`
}

/**
 * @param {date} date
 * @param {string=""} separator
 * @returns Returns the HHMMSS string representation of the datetime with each datetime parts separated by the separator.
 */
function HHMMSS(date, separator) {
  separator = separator || ""
  if (!date) date = new Date()
  return `${padNumber(date.getHours())}${separator}${padNumber(date.getMinutes())}${separator}${padNumber(date.getSeconds())}`
}

/**
 * @param {date} date
 * @param {string=""} separator
 * @returns Returns the DDMMYYYY string representation of the date with each date parts separated by the separator.
 */
function DDMMYYYY(date, separator) {
  separator = separator || ""
  if (!date) date = new Date()
  return `${padNumber(date.getDate())}${separator}${padNumber(1 + date.getMonth())}${separator}${date.getFullYear()}`
}

/**
 * @param {date} date
 * @param {number} nbDays
 * @returns Adds the number of business days to the given date and return it. The functions accounts for non-working days (2 days per period of 7 days) by adding them.
 */
function addBusinessDays(date, nbDays) {
  date = new Date(date.getTime())
  const day = date.getDay()
  date.setDate(date.getDate() + nbDays + (day === 6 ? 2 : +!day) + Math.floor((nbDays - 1 + (day % 6 || 1)) / 5) * 2)
  return date
}

/**
 * WARNING!! This function is deprecated and will be removed soon (after release 11/04/2023). Use dateAdd(date, { months }) instead
 * @param {date} date
 * @param {number} nbMonths
 * @param {number} day
 * @returns Adds the number of months to the given date and set the day to the provided one if any then return the date.
 */
function dateAddMonths(date, nbMonths, day) {
  if (!date) return date

  if (day === undefined || day === null) {
    //TODO : BUG => We must do "+ 1" and not "+ 2" because the third parameter (which is 0) allows us to return the previous day, i.e. the last day of the previous month
    //With + 2 : 2023-02-28T14:28:32.000Z + 1 month => 2023-03-30T13:28:32.000Z (If correction: 2023-03-31T13:28:32.000Z)
    //With + 2 : 2023-02-28T00:00:00.000Z + 12 months => 2024-03-02T00:00:00.000Z (If correction: 2024-02-29T00:00:00.000Z)
    //With + 2 : 2023-02-28T01:03:03.000Z - 1 month => 2023-01-28T01:03:03.000Z (If correction: 2023-01-31T01:03:03.000Z)
    //But Tri prefers to wait (13/10/2023) : non ne change rien, on n'a pas la coverage nécessaire pour vérifier
    if (dateIsLastDayOfMonth(date)) day = new Date(date.getFullYear(), date.getMonth() + nbMonths + 2, 0).getDate() // get last day of next month
    else day = date.getDate()
  }

  return new Date(date.getFullYear(), date.getMonth() + nbMonths, day, date.getHours(), date.getMinutes(), date.getSeconds())
}

/**
 * BE CAREFUL to understand what this does regarding hour, as the name can be misleading.
 * This API does NOT convert from or to UTC.
 * There are 2 cases :
 *  - no hour is given, e.g. 2020-12-01 => returns a data with hour 00:00 - it does not mean it is 00:00 in utc or local time, it means hour is *not* important
 *  - some hour is given (ISO date) => returns a date with that exact hour
 * @param {string} strDate
 * @param {string} locale
 * @returns Returns a date from a string. It is based on the standard Date.parse() function except that it supports several other formats YYYYMMDD, YYYY-MM-DD, DD-MM-YYYY/MM-DD-YYYY. Locale is used only in the last case to decide if MM comes before DD. Time portion is removed.
 */
function utcParseDate(strDate, locale) {
  if (!strDate) return strDate
  if (strDate instanceof Date) return strDate

  if (!Number.isInteger(strDate.charAt[1])) {
    const today = dateNoTime(new Date())
    switch (strDate.toLowerCase()) {
      case "undefined":
        return undefined
      case "today":
        return today
      case "yesterday":
        return dateAdd(today, { days: -1 })
      case "tomorrow":
        return dateAdd(today, { days: 1 })
      case "daybeforeyesterday":
      case "twodaysago":
        return dateAdd(today, { days: -2 })
      case "threedaysago":
        return dateAdd(today, { days: -3 })
      case "fourdaysago":
        return dateAdd(today, { days: -4 })
      case "fivedaysago":
        return dateAdd(today, { days: -5 })
      case "sixdaysago":
        return dateAdd(today, { days: -6 })
      case "fromoneweek":
      case "aweekago":
        return dateAdd(today, { days: -7 })
      case "fromonemonth":
      case "amonthago":
        return dateAdd(today, { months: -1 })
      case "threemonthsago":
      case "threemonthago":
      case "fromthreemonths":
      case "fromthreemonth": // this one is buggy but we need to check in all implementations to remove it safely => keeping it for now
        return dateAdd(today, { months: -3 })
      case "fromoneyear":
      case "ayearago":
        return dateAdd(today, { years: -1 })
      case "firstdayofmonth":
        return dateAdd(today, { day: 1 })
      case "firstdayofyear":
        return dateAdd(today, { months: 0, day: 1 })
      case "firstdayofnextmonth":
        return dateAdd(today, { months: 1, day: 1 })
      case "firstdayoflastmonth":
        return dateAdd(today, { months: -1, day: 1 })
      case "lastdayofmonth":
        return dateAdd(today, { months: 1, day: 0 })
      case "lastdayoflastmonth":
        return dateNoTime(new Date(today.getFullYear(), today.getMonth(), 0))
      case "dayaftertomorrow":
      case "intwodays":
        return dateAdd(today, { days: 2 })
      case "afterthreedays":
      case "inthreedays":
        return dateAdd(today, { days: 3 })
      case "afterfourdays":
      case "infourdays":
        return dateAdd(today, { days: 4 })
      case "afterfivedays":
      case "infivedays":
        return dateAdd(today, { days: 5 })
      case "afteroneweek":
      case "inaweek":
        return dateAdd(today, { days: 7 })
    }
  }

  if (strDate.indexOf("T") === -1) {
    // not an ISO date. Try to convert all sort of formats to YYYY-MM-DD so that we can use parseDate safely
    let split =
      strDate.indexOf("-") >= 0
        ? strDate.split("-")
        : strDate.indexOf("/") >= 0
        ? strDate.split("/")
        : strDate.indexOf(".") >= 0
        ? strDate.split(".")
        : null
    if (!split) {
      // assume YYYYMMDD
      if (strDate.length === 8) split = [strDate.substring(0, 4), strDate.substring(4, 6), strDate.substring(6, 8)]
      else if (strDate.length === 14) {
        // assume YYYYMMDDHHMMSS
        return new Date(
          Date.UTC(
            +strDate.slice(0, 4), // Year
            +strDate.slice(4, 6) - 1, // Month
            +strDate.slice(6, 8), // Date
            +strDate.slice(8, 10), // Hours
            +strDate.slice(10, 12), // Minutes
            +strDate.slice(12, 14), // Seconds
            +strDate.slice(15, 18), // Milliseconds
          ),
        )
      } else throw Error(genericErrors.INVALID_DATE + strDate)
    }
    if (split.length !== 3) throw Error(genericErrors.INVALID_DATE + strDate)
    if (split[2].length === 4) {
      if (!locale) throw Error(genericErrors.MISSING_LOCALE)
      // DDMMYYYY or MMDDYYYY ?
      const right = locale.split("-")[1]
      if (right === "US") split = [split[2], split[0], split[1]]
      else split = [split[2], split[1], split[0]]
    }
    if (split[1].length === 1) split[1] = "0" + split[1]
    if (split[2].length === 1) split[2] = "0" + split[2]
    strDate = split.join("-") // now we have YYYY-MM-DD
    const ms = Date.parse(strDate)
    // set to 00h00
    const localDate = new Date(ms)
    return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()))
  } else {
    // ISO date. Keep hours and seconds
    const ms = Date.parse(strDate)
    return new Date(ms)
  }
}

/**
 * @param {date} date
 * @param {number=1} month
 * @returns Returns the given date moved of the number of months and set to the first day.
 */
function dateFirstDayOfNextMonth(date, month) {
  if (month === undefined || month === null) month = 1
  return new Date(Date.UTC(date.getFullYear(), date.getMonth() + month, 1))
}

/**
 * @param {date} date
 * @returns Returns the given date moved to the next semester and set to the first day.
 */
function dateFirstDayOfNextSemester(date) {
  let month
  if ([0, 6].includes(date.getMonth())) month = 6
  if ([1, 7].includes(date.getMonth())) month = 5
  if ([2, 8].includes(date.getMonth())) month = 4
  if ([3, 9].includes(date.getMonth())) month = 3
  if ([4, 10].includes(date.getMonth())) month = 2
  if ([5, 11].includes(date.getMonth())) month = 1

  return dateFirstDayOfNextMonth(date, month)
}

/**
 * @param {date} date
 * @param {number=1} months
 * @param {number=1} day
 * @returns Returns the given date moved of the number of months and set to the given day.
 */
function dateNextMonth(date, months, day) {
  if (day === undefined || day === null) day = 1
  if (months === undefined || months === null) months = 1

  return new Date(Date.UTC(date.getFullYear(), date.getMonth() + months, day))
}

/**
 * @param {date} date
 * @param {number=1} day
 * @returns Returns the given date moved to the next quarter and set to the given day.
 */
function dateNextQuarter(date, day) {
  let month
  if ([0, 3, 6, 9].includes(date.getMonth())) month = 3
  if ([1, 4, 7, 10].includes(date.getMonth())) month = 2
  if ([2, 5, 8, 11].includes(date.getMonth())) month = 1

  return dateNextMonth(date, month, day)
}

/**
 * @param {date} date
 * @param {number=1} day
 * @returns Returns the given date moved to the next semester and set to the given day.
 */
function dateNextSemester(date, day) {
  let month
  if ([0, 6].includes(date.getMonth())) month = 6
  if ([1, 7].includes(date.getMonth())) month = 5
  if ([2, 8].includes(date.getMonth())) month = 4
  if ([3, 9].includes(date.getMonth())) month = 3
  if ([4, 10].includes(date.getMonth())) month = 2
  if ([5, 11].includes(date.getMonth())) month = 1

  return dateNextMonth(date, month, day)
}

/**
 * @param {date} date
 * @returns Returns the given date moved to the next quarter and set to the first day.
 */
function dateFirstDayOfNextQuarter(date) {
  let month
  if ([0, 3, 6, 9].includes(date.getMonth())) month = 3
  if ([1, 4, 7, 10].includes(date.getMonth())) month = 2
  if ([2, 5, 8, 11].includes(date.getMonth())) month = 1

  return dateFirstDayOfNextMonth(date, month)
}

/**
 * @param {date|string} date
 * @param {string} locale
 * @param {object} option
 * @returns Returns the date formatted with [toLocaleString](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString) if the given date is a date object. The locale is adjusted to match the locales supported by toLocaleString.
 */
function formatDateTime(date, locale, option) {
  if (!date) return date
  if (!locale) locale = defaultLocaleCode
  if (locale === "en-EU") locale = "en-GB"
  if (locale === "en-LT") locale = "lt-LT"
  if (typeof date === "string") return date
  if (option && "timeZone" in option && !option.timeZone) delete option.timeZone
  return date && date.toLocaleString(locale, option)
}

/**
 * @param {date} date
 * @param {string} period
 * @returns Adds the period to the date and return it. The period is a human readable string that supports the following patterns : 30min (minutes), 1h (hour), 2d (days), 4m (months), 3y (years).
 */
function addToDate(date, period) {
  const periodNumber = extractNumberFromString(period)

  let time = date.getTime()
  if (period.endsWith("min")) {
    time += 1000 * 60 * periodNumber
  } else if (period.endsWith("h")) {
    time += 1000 * 60 * 60 * periodNumber
  } else if (period.endsWith("d")) {
    time += 1000 * 60 * 60 * 24 * periodNumber
  } else if (period.endsWith("m")) {
    time += 1000 * 60 * 60 * 24 * 30 * periodNumber
  } else if (period.endsWith("y")) {
    time += 1000 * 60 * 60 * 24 * 365 * periodNumber
  }
  return new Date(time)
}

/**
 * March 31st / June 30th / September 30th / December 31st
 * @param {*} date
 * @returns
 */
function dateIsLastDayOfQuarter(date) {
  if (!date) date = dateNoTime(new Date()) // Default to today

  const isMarch31st = date.getMonth() === 2 && date.getDate() === 31
  const isJune30th = date.getMonth() === 5 && date.getDate() === 30
  const isSeptember30th = date.getMonth() === 8 && date.getDate() === 30
  const isDecember31st = date.getMonth() === 11 && date.getDate() === 31

  return isMarch31st || isJune30th || isSeptember30th || isDecember31st
}

/**
 * @param {date} date2
 * @param {date} date1
 * @returns Returns the number of days between the two dates.
 */
function dateDiffDays(date2, date1) {
  if (!date2 || !date1) return
  return (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24)
}

/**
 * @param {date} date2
 * @param {date} date1
 * @returns Returns the number of minutes between the two dates.
 */
function dateDiffMinutes(date2, date1) {
  if (!date2 || !date1) return
  return (date2.getTime() - date1.getTime()) / (1000 * 60)
}

/**
 * @param {date} date2
 * @param {date} date1
 * @returns Returns the number of days between the two dates.
 */
function dateDiffSeconds(date2, date1) {
  if (!date2 || !date1) return
  return (date2.getTime() - date1.getTime()) / 1000
}

/**
 * @param {date} date2
 * @param {date} date1
 * @returns Returns the number of months between the two dates.
 */
function dateDiffMonths(date2, date1) {
  const monthsPerYear = 12

  const yearDiff = date1.getFullYear() - date2.getFullYear()
  const monthDiff = date1.getMonth() - date2.getMonth()

  return yearDiff * monthsPerYear + monthDiff
}

/**
 * @param {date} date2
 * @param {date} date1
 * @returns Returns the number of years between the two dates.
 */
function dateDiffYears(date2, date1) {
  if (!date2 || !date1) return
  let years = date2.getFullYear() - date1.getFullYear()

  if (date2.getMonth() < date1.getMonth() || (date2.getMonth() === date1.getMonth() && date2.getDate() < date1.getDate())) {
    years--
  }

  return years
}

/**
 * @param {date} date
 * @param {number} minutes
 * @returns Adds the number of minutes to the given date and return it.
 */
function dateAddMinutes(date, minutes = 0) {
  if (!date || !minutes || !(date instanceof Date) || minutes === undefined || minutes === null) return date
  return new Date(date.getTime() + 1000 * 60 * minutes)
}

/**
 * @param {date} date
 * @param {number} hours
 * @returns Adds the number of hours to the given date and return it.
 */
function dateAddHours(date, hours = 0) {
  if (!date || !hours || !(date instanceof Date) || hours === undefined || hours === null) return date
  return new Date(date.getTime() + 1000 * 3600 * hours)
}

/**
 * @param {string} locale
 * @param {boolean} withDay
 * @returns Returns a string representing the date format applicable for the given locale.
 */
function momentDateFormat(locale, withDay) {
  // could not find a standard function to do this so hardcoding for now :-(
  let dateFormat
  if (locale) {
    dateFormat = getLocaleParameters(locale).dateFormat
  }
  if (!dateFormat) dateFormat = "DD-MM-YYYY"
  if (withDay) dateFormat = "ddd " + dateFormat
  return dateFormat
}

/**
 * Used to patch JSON date strings in requests' response received by the services and the front-end.
 * @param {object} input
 * @returns Mutates the given object with values containing a date string converted to a date object.
 */
function convertDateStringsToDates(input) {
  // Ignore things that aren't objects.
  if (typeof input !== "object") return input

  const regexIso8601 =
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z)?$/

  for (const key in input) {
    if (!Object.prototype.hasOwnProperty.call(input, key)) continue

    const value = input[key]
    let match
    // Check for string properties which look like dates.
    if (typeof value === "string" && (match = value.match(regexIso8601))) {
      const milliseconds = Date.parse(match[0])
      if (!isNaN(milliseconds)) {
        input[key] = new Date(milliseconds)
      }
    } else if (typeof value === "object") {
      // Recurse into object
      convertDateStringsToDates(value)
    }
  }
}

/**
 * Attempts to convert a date stored like DDMMYYYY, YYYYMMDDTHHMMSS, etc. to a date object.
 * It could be extended to support more varieties of pseudo date strings.
 * @returns {date}
 */
function convertPseudoDateStringToDate(pseudoDateString, options) {
  if (typeof pseudoDateString !== "string") return
  const { mask } = options || {}

  let dateString
  if (mask === "DD-MM-YYYY") {
    dateString = `${pseudoDateString.substring(6, 10)}-${pseudoDateString.substring(3, 5)}-${pseudoDateString.substring(0, 2)}T00:00:00.000Z`
  }
  // YYYYMMDDTHHMMSS
  if (pseudoDateString.length === 14) {
    dateString =
      `${pseudoDateString.substring(0, 4)}-${pseudoDateString.substring(4, 6)}-${pseudoDateString.substring(6, 8)}` +
      `T${pseudoDateString.substring(8, 10)}:${pseudoDateString.substring(10, 12)}:${pseudoDateString.substring(12, 14)}.000Z`
  }
  if (dateString) return new Date(dateString)
}

/**
 * @returns {date}
 */
function getFirstPeriodicEndDate({ date, frequency, withTime }) {
  if (!frequency || frequency === "30") return dateAdd(date, { months: 1 }, withTime)
  if (frequency === "90") return dateAdd(date, { quarters: 1 }, withTime)
  if (frequency === "180") return dateAdd(date, { semesters: 1 }, withTime)
  if (frequency === "360") return dateAdd(date, { years: 1 }, withTime) //TODO : bug if February (leap years)
  return dateAdd(date, { days: frequency }, withTime)
}

const getNextBusinessDayErrors = {
  DATE_NOT_FOUND: "DATE_NOT_FOUND|Valid date in calendar for date $ not found|",
}

/**
 * Search for the next opening date according to the calendar script
 * This API assume the given date is a "dateNoTime()" date
 * TODO : To be renamed to "adjustToNextBusinessDate()" or "toBusinessDate()"
 * @returns {date}
 */
function getNextBusinessDay({ calendar, allHolidays, date, withTime }) {
  if (!date) return date
  let i = 0
  for (; ; i++) {
    //TODO : Holidays are ignored!
    // if (!allHolidays?.includes(YYYYMMDD(date)) && !calendar?.weekEndDays?.includes(date.getDay())) break
    if (!allHolidays[YYYYMMDD(date)] && !calendar?.weekEndDays?.includes(date.getDay())) break
    date = dateNoTime(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1), withTime)
    if (i >= 1000) throw Error(getNextBusinessDayErrors.DATE_NOT_FOUND + date.toISOString())
  }
  return date
}

/**
 * Adjust if paymentDay is (for ex) 31 and the month is less days
 * @returns {date}
 */
function adjustPaymentDay({ date, paymentDay, withTime }) {
  if (paymentDay && date.getDate() < paymentDay) date = dateNoTime(new Date(date.getFullYear(), date.getMonth(), 0), withTime)
  return date
}

/**
 * period = 0 => firstPeriodicEndDate
 * @returns {date}
 */
function getPeriodicDate({ period, firstPeriodicEndDate, frequency, paymentDay, interestOnPaymentDay, withTime }) {
  //TODO : It is also necessary to test the presence of the "period" parameter!
  if (!firstPeriodicEndDate) throw Error(genericErrors.MISSING_FIELD_IN_ARGS + "'firstPeriodicEndDate'")

  if (!frequency || frequency >= 30) {
    let multiplier = 1
    if (frequency === "90") multiplier = 3
    if (frequency === "180") multiplier = 6
    if (["360", "365"].includes(frequency)) multiplier = 12

    let date = adjustPaymentDay({
      date: dateAdd(firstPeriodicEndDate, { months: period * multiplier, day: paymentDay }, withTime),
      paymentDay,
      withTime,
    })
    if (interestOnPaymentDay) date = dateAdd(date, { days: 1 }, withTime)
    return date
  }

  let date = firstPeriodicEndDate
  if (paymentDay) date = dateAdd(date, { day: paymentDay }, withTime)
  date = dateAdd(firstPeriodicEndDate, { days: period * (frequency === "7" ? 7 : 1) }, withTime)
  return date
}

export {
  addBusinessDays,
  addToDate,
  adjustPaymentDay,
  convertDateStringsToDates,
  convertPseudoDateStringToDate,
  dateAdd,
  dateAddDays,
  dateAddHours,
  dateAddMinutes,
  dateAddMonths,
  dateDiffDays,
  dateDiffMinutes,
  dateDiffMonths,
  dateDiffSeconds,
  dateDiffYears,
  dateFirstDayOfMonth,
  dateFirstDayOfNextMonth,
  dateFirstDayOfNextQuarter,
  dateFirstDayOfNextSemester,
  dateIsLastDayOfMonth,
  dateIsLastDayOfQuarter,
  dateLastDayOfMonth,
  dateNextMonth,
  dateNextQuarter,
  dateNextSemester,
  dateNoTime,
  DDMMYYYY,
  formatDate,
  formatDateTime,
  formatMilliseconds,
  getFirstPeriodicEndDate,
  getNextBusinessDay,
  getPeriodicDate,
  getTimezoneOffset,
  getWeekNumber,
  getWeekPeriod,
  HHMMSS,
  isValidDate,
  MMYYYY,
  momentDateFormat,
  parsePeriod,
  utcParseDate,
  YYYYMM,
  YYYYMMDD,
  YYYYMMDDHHMMSS,
  YYYYMMDDTHHMMSS,
}
