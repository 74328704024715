import axios from "axios"
import { v4 as uuidv4 } from "uuid"

import { convertDateStringsToDates } from "basikon-common-utils"

import { isAuthenticated, logout } from "@/_services/authentication"
import consoleService from "@/_services/console"
import { loc } from "@/_services/localization"
import { hasOidcLogoutUrl } from "@/_services/oidc"
import { hideSpinner, showSpinner } from "@/_services/spinner"
import { getHostConfig } from "@/_services/theming"
import { debug, httpHeaders, localStorageKeys } from "@/_services/utils"

let headerAuthorization
let userExternalContexts
let headerContextToken

export function getHeaderAuthorization() {
  const { memoizeToken } = getHostConfig()
  return headerAuthorization || (memoizeToken ? localStorage.getItem(localStorageKeys.HEADER_AUTH) : undefined)
}

export function getHeaderUserExternalContexts() {
  const { memoizeToken } = getHostConfig()
  return userExternalContexts || (memoizeToken ? localStorage.getItem(localStorageKeys.HEADER_USER_CONTEXTS) : undefined)
}

export function getHeaderContextToken() {
  const { memoizeToken } = getHostConfig()
  return headerContextToken || (memoizeToken ? localStorage.getItem(localStorageKeys.HEADER_CONTEXT_TOKEN) : undefined)
}

export function setHeaderAuthorization(auth) {
  headerAuthorization = auth
  const { memoizeToken } = getHostConfig()
  if (memoizeToken) localStorage.setItem(localStorageKeys.HEADER_AUTH, auth)
}

//When we want to use external extra context
export function setHeaderUserExternalContexts(userContextsHeaderValue, contextTokenHeaderValue) {
  userExternalContexts = userContextsHeaderValue
  headerContextToken = contextTokenHeaderValue

  const { memoizeToken } = getHostConfig()
  if (memoizeToken) {
    localStorage.setItem(localStorageKeys.HEADER_USER_CONTEXTS, userContextsHeaderValue)
    localStorage.setItem(localStorageKeys.HEADER_CONTEXT_TOKEN, contextTokenHeaderValue)
  }
}

export function setUserCurrentContextIndex(userContextIndex) {
  localStorage.setItem(localStorageKeys.USER_CURRENT_CONTEXT_INDEX, userContextIndex)
}

export function getUserCurrentContextIndex() {
  return localStorage.getItem(localStorageKeys.USER_CURRENT_CONTEXT_INDEX)
}

export function resetHeaderAuthorization() {
  headerAuthorization = undefined
  userExternalContexts = undefined
  headerContextToken = undefined
  localStorage.removeItem(localStorageKeys.HEADER_AUTH)
  localStorage.removeItem(localStorageKeys.HEADER_CONTEXT_TOKEN)
  localStorage.removeItem(localStorageKeys.HEADER_USER_CONTEXTS)
}

export function getAuthorizationToken() {
  return getHeaderAuthorization()?.substring("bearer ".length)
}

export function formatAxiosError(error) {
  if (error) {
    console.error(
      "====",
      error,
      error.response,
      error.response && error.response.data,
      error.response && error.response.data && error.response.data.message,
    )
  }

  let msg
  if (error.response && error.response.data) {
    msg = debug ? error.response.data.stack : error.response.data.message || error.response.data
    const data = error.response.data
    if (debug && data.stack) {
      msg = data.stack
    } else {
      const array = data.strings
      if (array) msg = loc(data.message, ...array)
      else msg = loc(data.message || data)
    }
    msg += " (" + error.response.status + ")"
  }
  if (!msg) msg = debug && error.stack ? error.stack : error.message
  if (msg === "Network Error") msg = loc("Network error") + ": " + loc("Check your internet connection")
  return loc(msg)
}

export function setupAxiosClient(baseUrl) {
  function sameOrigin(url) {
    return url.startsWith("/api") || url.startsWith(baseUrl)
  }

  headerAuthorization = getHeaderAuthorization()

  axios.interceptors.request.use(
    function (config) {
      if (consoleService.showAxiosInterceptors) console.log("Calling", config.method.toUpperCase(), config.url)
      showSpinner()
      if (sameOrigin(config.url) && headerAuthorization) config.headers.Authorization = headerAuthorization

      // if both headers are set axios will concatenate them
      // resulting in an invalid token from the server point of view
      if (config.headers.Authorization && config.headers.authorization) delete config.headers.authorization

      config.headers[httpHeaders.requestOriginator] = "basikon-client"
      config.headers[httpHeaders.requestId] = uuidv4() // for idempotent requests
      config.headers[httpHeaders.clientTimezone] = Intl.DateTimeFormat().resolvedOptions().timeZone
      return config
    },
    function (error) {
      hideSpinner()
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    function (response) {
      convertDateStringsToDates(response.data)
      if (sameOrigin(response.request.responseURL)) {
        const headers = response.headers
        if (headers && (headers.hasOwnProperty("Authorization") || headers.hasOwnProperty("authorization"))) {
          const auth = headers && (headers.Authorization || headers.authorization)
          // If the authorization header is returned it can be empty (an empty string), when calling logout for instance.
          // In this case although using setHeaderAuthorization works, as it would keep the key headerAuthorization but set an empty value,
          // it is cleaner to remove the completely the remove the key.
          if (auth) {
            setHeaderAuthorization(auth)
          } else {
            resetHeaderAuthorization()
          }
        }
      }
      hideSpinner()
      if (consoleService.showAxiosInterceptors) console.log("End calling NO error", response.config.url, "(" + response.status + ")")
      return response
    },
    async function (error) {
      //debugger
      hideSpinner()
      if (!error.response) {
        if (consoleService.showAxiosInterceptors) {
          console.log("End calling WITH error", error.config && error.config.url)
          console.log("Error:", error)
        }
      } else {
        if (consoleService.showAxiosInterceptors) {
          console.log("End calling WITH error", error.config && error.config.url, "(" + error.response.status + ")")
          console.log("Response data:", error.response.data)
        }
        if (sameOrigin(error.response.request.responseURL) && error.response.status === 401) {
          // We don't trigger the logout in any case because users can use the app without being connected,
          // notably in flow mode, so we have to precisely declare which cases we want to handle.
          // The OIDC case happens when users that were logged in through OIDC are hard reloading the app
          // and the platform token (from local storage) is invalid.
          // They are not authenticated anymore regarding the platform, but might still have a valid OIDC session.
          if (isAuthenticated() || hasOidcLogoutUrl()) {
            await logout({ errorMessage: "Session has expired" })
          }
        }
      }
      return Promise.reject(error)
    },
  )
}
