import React from "react"
import { Row } from "react-bootstrap"

import Card from "@/_components/Card"
import FormInput from "@/_components/FormInput"
import { loc } from "@/_services/localization"

const cardNames = {
  INFORMATION: "Information",
}

const voting = {
  EntitiesPage: {
    columns: [
      { title: "Registration", name: "registration", linkTo: "/voting/{registration}" },
      { title: "Type", name: "type", select: "votingType" },
      { title: "Status", name: "status", select: "votingStatus", badge: true },
      { title: "Name", name: "name" },
      { title: "Start date", name: "startDate", type: "date" },
      { title: "End date", name: "endDate", type: "date" },
    ],
    searchFields: [
      [
        { field: "type", select: "votingType", colProps: { xs: 12, sm: 4 } },
        { field: "name", colProps: { xs: 12, sm: 8 }, regex: true },
      ],
      [
        { field: "status", type: "multiple", select: "votingStatus", colProps: { xs: 12, sm: 4 } },
        { field: "startDate", type: "date", colProps: { xs: 12, sm: 4 } },
        { field: "endDate", type: "date", colProps: { xs: 12, sm: 4 } },
      ],
    ],
  },
  EntityPage: {
    cardNames: Object.values(cardNames),
    getCardComponent,
    defaultCards: [{ card: "Workflow" }, { card: "Information" }],
  },
}

function getCardComponent({ card: cardName, entity, handleSetEntityState, props = {} } = {}) {
  if (cardName === cardNames.INFORMATION) {
    const formInputProps = {
      obj: entity,
      onSetState: handleSetEntityState,
    }
    return (
      <Card title={loc(props.title || "Information")} noCard={props.noCard} collapse={props.collapse}>
        <Row>
          <FormInput field="type" select="votingType" colProps={{ xs: 12, md: 3 }} {...formInputProps} />
          <FormInput field="name" colProps={{ xs: 12, md: 3 }} {...formInputProps} />
          <FormInput field="startDate" type="date" colProps={{ xs: 12, md: 3 }} {...formInputProps} />
          <FormInput field="endDate" type="date" colProps={{ xs: 12, md: 3 }} {...formInputProps} />
          <FormInput field="description" type="textarea" rows={2} colProps={{ xs: 12 }} {...formInputProps} />
        </Row>
      </Card>
    )
  }
}

export default voting
