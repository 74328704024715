import axios from "axios"
import React from "react"
import { Col, Row } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import InventoriesModal from "@/accounting/InventoriesModal"
import { toQueryString } from "basikon-common-utils"

const inventory = {
  // <EntitiesPage/>
  cardFooter,
  columns: [
    { title: "Registration", name: "registration", linkTo: "/inventory/{registration}" },
    { title: "Type", name: "type", select: "inventoryType" },
    { title: "Status", name: "status", select: "inventoryStatus", badge: true },
    { title: "Date", name: "generationDate", type: "date" },
    { title: "Amount", name: "financedAmount", excelFormat: "currency", type: "currency", currencyPath: "currencyCode" },
    { title: "Ref", name: "inventoryReference" },
    { title: "Person", name: "personRegistration", linkTo: "/person/{personRegistration}" },
    { title: "Creditline", name: "creditLineRegistration", linkTo: "/credit-line/{creditLineRegistration}" },
    { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
    { title: "Invoice", name: "invoiceRegistration", linkTo: "/invoice/{invoiceRegistration}" },
    { title: "Guarantee", name: "guaranteeRegistration" },
  ],
  searchFields: [
    [
      { field: "type", select: "inventoryType", colProps: { xs: 12, sm: 4 } },
      { field: "contract", colProps: { xs: 12, sm: 8 }, regex: true },
    ],
    [
      { field: "status", type: "multiple", select: "inventoryStatus", colProps: { xs: 12, sm: 4 } },
      { field: "person", colProps: { xs: 12, sm: 8 }, regex: true },
    ],
  ],
}

function cardFooter({ component, pageConfig }) {
  const { loadingInventories, showInventoriesModal } = component.state

  const handleInventoriesModalClose = async date => {
    if (!date) return

    component.setState({ loadingInventories: true, showInventoriesModal: false })

    try {
      const inventories = (await axios.get(`/api/script/runs/run-inventories${toQueryString({ date })}`)).data
      component.setState({ loadingInventories: false })
      component.componentGetEntities()

      addNotification(loc`${inventories.length} inventories generated`)
    } catch (error) {
      component.setState({ loadingInventories: false })
      addOops(error)
    }
  }

  return (
    <Row className="mt-theme">
      <Col xs={12}>
        <CustomButton
          bsStyle="primary"
          bsSize="small"
          pullRight
          className="inline-flex align-items-center"
          image="icn-money-check icn-sm mr-5px"
          label={loc`Generate inventories`}
          loading={loadingInventories}
          onClick={() => component.setState({ showInventoriesModal: true })}
          hidden={pageConfig?.generateInventoriesButton?.hidden}
        />
        {showInventoriesModal && <InventoriesModal loading={loadingInventories} onClose={handleInventoriesModalClose} />}
      </Col>
    </Row>
  )
}

export default inventory
