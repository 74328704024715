import axios from "axios"
import { coreTenants, getFilterFunction, pruneObject, toQueryString } from "basikon-common-utils"
import React, { createRef } from "react"
import { Col, Grid, Row } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"

import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import EntitySearchModal from "@/_components/EntitySearchModal"
import LayoutCard from "@/_components/LayoutCard"
import Table, { mergeColumns } from "@/_components/Table"

import PersonHierarchyComponent from "@/person/PersonHierarchyComponent"

import { getLabel, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import {
  getOptions,
  getPageConfig,
  getPartnerRegistration,
  getPersonRegistration,
  getTenant,
  getUser,
  hasPermission,
} from "@/_services/userConfiguration"
import {
  applyPageAdvancedSearchConfig,
  getClientRouteKey,
  getEntities,
  hasQueryParamsChanged,
  mergeQueryParams,
  searchParamToObject,
} from "@/_services/utils"

class UsersPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const pageConfigKey = getClientRouteKey(clientRoute) || "UsersPage"
    const user = getUser()
    const { isAdmin, isSuperAdmin } = user || {}
    const pageConfig = getPageConfig(pageConfigKey)
    const defaultColumns = [
      { title: "Username", name: "username" },
      { title: "Name", name: "name" },
      { title: "Email", name: "email" },
      { title: "Profiles", name: "profiles" },
      { title: "Can impersonate", name: "canImpersonate", hidden: true },
      isAdmin && { title: "Is admin", name: "isAdmin" },
      isSuperAdmin && { title: "Is super admin", name: "isSuperAdmin" },
      { title: "Locale", name: "locale", select: "locale" },
      { title: "Person", name: "person", linkTo: "/person/{personRegistration}" },
      { title: "Partner", name: "partner", linkTo: "/person/{partnerRegistration}" },
      { title: "Organization", name: "organization", linkTo: "/person/{orgaRegistration}" },
      { title: "Locked", name: "isLocked", hidden: true },
      { title: "MFA", name: "hasActivatedMfa", hidden: true },
      { title: "Last login", name: "lastLoginDate", type: "datetime", hidden: true },
    ].filter(it => it)

    const columns = mergeColumns(defaultColumns, pageConfig?.columns)

    this.state = {
      entityName: "User",
      users: [],
      loading: false,
      showPersonsSearchModal: false,
      pageConfigKey,
      pageConfig,
      isSupportTenant: getTenant() === coreTenants.SUPPORT,
      canCreateUsers: hasPermission("write /api/core/users"),
      columns,
      paginationParams: null,
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    const { pageConfigKey } = this.state
    const { location } = this.props

    getList("userProfile", () => this.setState({ userProfileLoaded: 1 }))

    const queryParams = searchParamToObject(location.search)
    this.getUsers(queryParams)

    applyPageAdvancedSearchConfig(pageConfigKey, userSearchFields)
  }

  componentDidUpdate(prevProps) {
    if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) {
      this.getUsers()
    }
  }

  getPersonUsers = users => {
    return users.reduce((acc, v) => {
      if (v.personRegistration) acc[v.personRegistration] = v.username
      return acc
    }, {})
  }

  getUsers = async ({ params = {}, isAdvancedSearch, nextPagination } = {}) => {
    const { pageConfig, users: stateEntities, entityName } = this.state
    const { location, history } = this.props
    const queryParams = { ...searchParamToObject(location.search), ...params }
    const getEntitiesQueryParams = {
      ...pageConfig?.defaultQuery,
      ...pruneObject(queryParams),
      ...pageConfig?.query,
    }

    this.setState({ loading: true })

    if (nextPagination) {
      const { data: moreEntities, paginationParams } = await getEntities(entityName, getEntitiesQueryParams, { nextPagination })
      this.setState({ users: [...stateEntities, ...moreEntities], paginationParams, personUsers: this.getPersonUsers(moreEntities), loading: false })
      this.preventAutoRefresh.current = true
      delete queryParams.range
      history.replace(`${location.pathname}${toQueryString(queryParams)}`)
      this.preventAutoRefresh.current = false
      return
    }

    const { data: users, paginationParams } = await getEntities(entityName, getEntitiesQueryParams, { nextPagination })

    let person
    try {
      const personOrPartnerRegistration = getPersonRegistration() || getPartnerRegistration()
      person = personOrPartnerRegistration && (await axios.get(`/api/person/persons/${personOrPartnerRegistration}`)).data
    } catch (error) {
      addOops(error)
    }

    this.setState({ person, users, personUsers: this.getPersonUsers(users), loading: false, paginationParams })

    if (params) {
      this.preventAutoRefresh.current = true
      if (isAdvancedSearch) queryParams.page = 1
      history.replace(`${location.pathname}${mergeQueryParams(location.search, queryParams)}`)
      this.preventAutoRefresh.current = false
    }
  }

  handlePersonsSearchModalClose = async person => {
    if (person) {
      const profiles = []
      if (person.roles.find(it => it.role === "CLIENT")) profiles.push("CLIENT")
      if (person.roles.find(it => it.role === "SALES")) profiles.push("SALES")
      if (profiles.length === 0) profiles.push("CLIENT") // when no profile given, assume client

      try {
        const user = (
          await axios.post(`/api/core/users${getOptions("syncPerson") !== false ? "?syncPerson=1" : ""}`, {
            username:
              [person.firstName && person.firstName.toLowerCase(), person.lastName && person.lastName.toLowerCase()].join(".") + "@" + getTenant(),
            lastName: person.lastName,
            firstName: person.firstName,
            locale: getLocale(),
            email: person.email,
            personRegistration: person.registration,
            profiles,
          })
        ).data
        const users = (await axios.get("/api/core/users")).data
        this.setState({ users, search: user.registration })
        addNotification("User created")
      } catch (error) {
        addOops(error)
      }
    }
    this.setState({ showPersonsSearchModal: false })
  }

  handleRelationsNodeClick = async key => {
    const { history } = this.props
    let users = (await axios.get("/api/core/users?person=" + key)).data
    if (users[0]) history.push("/user/" + users[0].username)
    else addNotification("Not a user")
  }

  render() {
    const { person, personUsers, loading, showPersonsSearchModal, pageConfig, canCreateUsers, columns, paginationParams } = this.state
    const { location, history } = this.props
    const queryParams = searchParamToObject(location.search)
    const { filter = "" } = queryParams
    const title = loc(pageConfig?.title || "Users")
    const { diagramRelations } = pageConfig || {}
    const users = filter?.trim() ? filterUsers(this.state.users, filter) : this.state.users

    // use the ternary syntax instead of && so that values are properly rendered in Excel
    const data = users?.map(user => ({
      ...user,
      isLocked: user.isLocked ? <i className="icn-check icn-xs" /> : "",
      hasActivatedMfa: user.hasActivatedMfa ? <i className="icn-check icn-xs" /> : "",
      username: {
        content: (
          <Link className={user.isLocked ? "text-strike" : ""} to={`/user/${user.username?.replaceAll(".", "&period;")}`}>
            {user.username}
          </Link>
        ),
      },
      locale: user.locale,
      name: `${user.firstName || ""} ${user.lastName || ""}`,
      email: user.email,
      profiles: (user.profiles || []).map(profile => getLabel("userProfile", profile)).join(", "),
      canImpersonate: user.canImpersonate ? <i className="icn-check icn-xs" /> : "",
      isAdmin: user.isAdmin ? <i className="icn-check icn-xs" /> : "",
      isSuperAdmin: user.isSuperAdmin ? <i className="icn-check icn-xs" /> : "",
      person: user.person || user.personRegistration,
      partner: user.partner || user.partnerRegistration,
      organization: user.organization || user.orgaRegistration,
      personRegistration: user.personRegistration,
      orgaRegistration: user.orgaRegistration,
    }))

    return (
      <Grid className="users-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard
            noCard
            rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "users-kpi", noCard: false, acceptMissing: true } }]}
          />
        )}

        <Row>
          <Col xs={12}>
            <Card
              title={title}
              action={
                canCreateUsers && (
                  <CustomButton
                    fill
                    pullRight
                    bsSize="small"
                    bsStyle="primary"
                    className="inline-flex-center"
                    hidden={pageConfig?.addButton?.hidden}
                    onClick={() => history.push(pageConfig?.addButton?.linkTo || "/user")}
                  >
                    <i className="icn-plus icn-xs mr-5px" />
                    {loc(pageConfig?.addButton?.label || "Add")}
                  </CustomButton>
                )
              }
            >
              <Table
                useSearchRowComponent
                entityName="User"
                getEntities={this.getUsers}
                searchFields={userSearchFields}
                exportDataFileName={title}
                overflowX
                showRowsCount
                data={data}
                columns={columns}
                loading={loading}
                pageConfig={pageConfig}
                paginationParams={paginationParams}
              />
            </Card>
          </Col>
        </Row>

        {person && (
          <Row>
            <Col xs={12}>
              <PersonHierarchyComponent
                entityServerRoute={"/api/person/persons"}
                readOnly
                title={loc`Organization`}
                collapse
                person={person}
                persons={person?.persons}
                descendants={person?.descendants}
                personUsers={personUsers}
                diagramSizeLarge
                onNodeClicked={this.handleRelationsNodeClick}
                diagramRelations={diagramRelations}
              />
            </Col>
          </Row>
        )}

        {showPersonsSearchModal && <EntitySearchModal query={{ type: "I" }} entityName="Person" onClose={this.handlePersonsSearchModalClose} />}
      </Grid>
    )
  }
}

export default withRouter(UsersPage)

function filterUsers(users = [], filter) {
  if (!filter) return users
  const includes = getFilterFunction(filter, getLocale())

  return users.filter(user => {
    const localeLabel = getLabel("locale", user.locale)
    const profiles = (user.profiles || []).map(profile => getLabel("userProfile", profile)).join(", ")

    return (
      includes(user.locale) ||
      includes(localeLabel) ||
      includes(user.username) ||
      includes(user.personRegistration) ||
      includes(user.partnerRegistration) ||
      includes(user.email) ||
      includes(user.firstName) ||
      includes(user.lastName) ||
      includes(`${user.firstName || ""} ${user.lastName || ""}`) ||
      includes(profiles) ||
      includes(user.signatureUserId)
    )
  })
}

export const userSearchFields = [
  [
    { field: "username", colProps: { xs: 12, sm: 4 } },
    { field: "firstName", regex: "fromStart", colProps: { xs: 12, sm: 4 } },
    { field: "lastName", regex: "fromStart", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "email", type: "email", colProps: { xs: 12, sm: 4 } },
    { field: "profiles", type: "multiple", select: "userProfile", colProps: { xs: 12, sm: 8 } },
  ],
  [
    { field: "mobile", type: "phone", colProps: { xs: 12, sm: 4 } },
    { field: "personRegistration", label: "Person", searchEntityName: "Person", actionHidden: true, colProps: { xs: 12, sm: 4 } },
    {
      field: "partnerRegistration",
      label: "Partner",
      searchEntityName: "Person",
      query: { role: ["PARTNER", "BUSINESSUNIT", "AGENCY"] },
      actionHidden: true,
      defaultOptions: true,
      colProps: { xs: 12, sm: 4 },
    },
  ],
  [
    {
      field: "fromLastLoginDate",
      type: "date",
      colProps: { xs: 12, sm: 4 },
    },
    {
      field: "toLastLoginDate",
      type: "date",
      colProps: { xs: 12, sm: 4 },
    },
  ],
]
