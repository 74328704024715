import { getFilterFunction, pruneObject, toQueryString } from "basikon-common-utils"
import React, { createRef } from "react"
import { Grid } from "react-bootstrap"

import Card from "@/_components/Card"
import EntityTransitionsButton from "@/_components/EntityTransitionsButton"
import LayoutCard from "@/_components/LayoutCard"
import Table, { mergeColumns } from "@/_components/Table"

import { getEntityDisplay, handleEntitiesPageRowClick, handleEntitiesPageSelectAll } from "@/_services/entity"
import { getLabel, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import {
  applyPageAdvancedSearchConfig,
  applyPageConfigFields,
  formatCurrency,
  getClientRouteKey,
  getEntities,
  hasQueryParamsChanged,
  mergeQueryParams,
  searchParamToObject,
} from "@/_services/utils"

class AmendmentsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const pageConfigKey = getClientRouteKey(clientRoute) || "AmendmentsPage"
    const pageConfig = getPageConfig(pageConfigKey)
    const defaultColumns = [
      { title: "Registration", name: "registration", linkTo: "/amendment/{registration}" },
      { title: "Organization", name: "organization.name", hidden: true },
      { title: "Type", name: "type", select: "amendmentType" },
      { title: "Status", name: "status", select: "amendmentStatus", badge: true },
      { title: "Contract", name: "contract", linkTo: "/contract/{contractRegistration}" },
      { title: "Client", name: "client", linkTo: "/person/{clientRegistration}" },
      { title: "Partner", name: "partner", linkTo: "/person/{partnerRegistration}" },
      { title: "Date", name: "date", type: "date" },
      { title: "Validity date", name: "validityDate", type: "date" },
      { title: "Financed amount", name: "financedAmountExclTax", type: "currency" },
    ]

    this.state = {
      entityName: "Amendment",
      pinnedData: [],
      amendments: [],
      loading: false,
      transitions: [],
      transitionsLoading: false,
      selectedEntities: new Set(),
      pageConfigKey,
      pageConfig,
      columns: mergeColumns(defaultColumns, pageConfig?.columns),
      paginationParams: null,
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    const { pageConfigKey } = this.state
    this.getAmendments()

    getList("amendmentStatus", () => this.setState({ loaded: true }))
    getList("amendmentType", () => this.setState({ loaded: true }))

    applyPageAdvancedSearchConfig(pageConfigKey, amendmentSearchFields)
  }

  componentDidUpdate(prevProps) {
    if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) {
      this.getAmendments()
    }
  }

  getAmendments = async ({ params = {}, isAdvancedSearch, nextPagination } = {}) => {
    const { pageConfig, columns, entityName, amendments: stateEntities } = this.state
    const { location, history } = this.props
    const { search, pathname } = location
    const fetchPersonsName = pageConfig?.fetchPersonsName
    const include = [
      fetchPersonsName === false ? undefined : "personsNames",
      columns.find(it => it.name === "organization.name" && !it.hidden) && "organizationName",
    ].filter(it => it)
    const queryParams = { ...searchParamToObject(search), ...params, include }
    const getEntitiesQueryParams = {
      ...pageConfig?.defaultQuery,
      ...pruneObject(queryParams),
      ...pageConfig?.query,
      include: pageConfig?.query?.include || queryParams.include,
      projection: pageConfig?.query?.projection || queryParams.projection,
    }

    this.setState({ loading: true })

    if (nextPagination) {
      const { data: moreEntities, paginationParams } = await getEntities(entityName, getEntitiesQueryParams, { nextPagination })
      this.setState({ amendments: [...stateEntities, ...moreEntities], paginationParams, loading: false })
      this.preventAutoRefresh.current = true
      delete queryParams.range
      history.replace(`${pathname}${toQueryString(queryParams)}`)
      this.preventAutoRefresh.current = false
      return
    }

    const [{ data: amendments, paginationParams }, pinnedData] = await Promise.all([
      getEntities(entityName, getEntitiesQueryParams),
      fetchPinnedEntities({ entityName, queryParams: getEntitiesQueryParams }),
    ])

    this.setState({
      amendments: applyPageConfigFields(pageConfig, amendments),
      paginationParams,
      pinnedData: this.formatTableData(applyPageConfigFields(pageConfig, pinnedData)),
      loading: false,
      selectedEntities: new Set(), // Reset selected entities after getting new entities
    })

    if (params) {
      this.preventAutoRefresh.current = true
      if (isAdvancedSearch) queryParams.page = 1
      history.replace(`${pathname}${mergeQueryParams(search, queryParams)}`)
      this.preventAutoRefresh.current = false
    }
  }

  formatTableData = data => {
    return data.map(amendment => {
      const { persons = [] } = amendment

      const { personRegistration: clientRegistration, person: client } = persons.find(p => p.role === "CLIENT") || {}
      const { personRegistration: partnerRegistration, person: partner } = persons.find(p => p.role === "PARTNER") || {}

      return {
        ...amendment,
        id: amendment.registration,
        contract: amendment.contractRegistration,
        client: client?.name || clientRegistration,
        clientRegistration,
        partner: partner?.name || partnerRegistration,
        partnerRegistration,
        financedAmountExclTax: amendment?.quotations?.[0]?.financedAmountExclTax,
      }
    })
  }

  render() {
    const {
      entityName,
      amendments = [],
      selectedEntities,
      transitions = [],
      loading,
      transitionsLoading,
      pageConfig,
      columns,
      pinnedData,
      paginationParams,
    } = this.state
    const { location } = this.props
    const title = loc(pageConfig?.title || "Amendments")
    const queryParams = searchParamToObject(location.search)
    const filteredAmendments = queryParams.filter?.trim() ? filterAmendments(amendments, queryParams.filter) : amendments

    const action = selectedEntities.size ? (
      <EntityTransitionsButton
        entities={amendments}
        entityName={entityName}
        transitions={transitions}
        selectedEntities={selectedEntities}
        transitionsLoading={transitionsLoading}
        handleSelectAll={async selectedEntities => await handleEntitiesPageSelectAll(entityName, this, selectedEntities)}
      />
    ) : null

    const data = this.formatTableData(filteredAmendments)

    return (
      <Grid className="amendments-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard noCard rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "amendments-kpi", noCard: false } }]} />
        )}

        <Card title={title} action={action}>
          <Table
            useSearchRowComponent
            exportDataFileName={title}
            getEntities={this.getAmendments}
            searchFields={amendmentSearchFields}
            data={data}
            pinnedData={pinnedData}
            entityName={entityName}
            columns={columns}
            loading={loading}
            selectedEntities={selectedEntities}
            onRowClick={!pageConfig?.transitionsButton?.hidden && (async row => await handleEntitiesPageRowClick(entityName, this, row))}
            pageConfig={pageConfig}
            showRowsCount
            paginationParams={paginationParams}
          />
        </Card>
      </Grid>
    )
  }
}

export default AmendmentsPage

export function filterAmendments(amendments = [], filter) {
  if (!filter) return amendments

  const locale = getLocale()
  const includes = getFilterFunction(filter, locale)

  return amendments.filter(amendment => {
    const typeLabel = getLabel("amendmentType", amendment.type)
    const statusLabel = getLabel("amendmentStatus", amendment.status)

    const { personRegistration: clientRegistration, person: client } = (amendment.persons || []).find(p => p.role === "CLIENT") || {}
    const { personRegistration: partnerRegistration, person: partner } = (amendment.persons || []).find(p => p.role === "PARTNER") || {}

    return (
      includes(typeLabel) ||
      includes(statusLabel) ||
      includes(client?.name) ||
      includes(partner?.name) ||
      includes(amendment.type) ||
      includes(amendment.name) ||
      includes(amendment.date) ||
      includes(amendment.status) ||
      includes(clientRegistration) ||
      includes(partnerRegistration) ||
      includes(amendment.description) ||
      includes(amendment.registration) ||
      includes(amendment.validityDate) ||
      includes(getEntityDisplay(amendment)) ||
      includes(amendment.contractRegistration) ||
      includes(amendment?.quotations?.[0]?.financedAmountExclTax) ||
      includes(formatCurrency(amendment?.quotations?.[0]?.financedAmountExclTax, locale))
    )
  })
}

export const amendmentSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", select: "amendmentStatus", colProps: { xs: 12, sm: 4 } },
    { field: "type", type: "multiple", select: "amendmentType", colProps: { xs: 12, sm: 8 } },
  ],
  [
    {
      field: "contract",
      searchEntityName: "Contract",
      placeholder: "Type to search for a contract",
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
    { field: "fromDate", type: "date", colProps: { xs: 12, sm: 4 } },
    { field: "toDate", type: "date", colProps: { xs: 12, sm: 4 } },
  ],
]
