import { getFilterFunction, pruneObject, toQueryString } from "basikon-common-utils"
import React, { createRef, Suspense } from "react"
import { Grid, OverlayTrigger, Tooltip } from "react-bootstrap"

import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import LayoutCard from "@/_components/LayoutCard"
import Table, { mergeColumns } from "@/_components/Table"

import { getEntityDisplay } from "@/_services/entity"
import { getLabel } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import { getClientRouteKey, getEntities, hasQueryParamsChanged, mergeQueryParams, searchParamToObject } from "@/_services/utils"

const NewPartnerModal = React.lazy(() => import("@/financing/NewPartnerModal.jsx"))

class AgreementsPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const pageConfigKey = getClientRouteKey(clientRoute) || "AgreementsPage"

    const pageConfig = getPageConfig(pageConfigKey)
    const defaultColumns = [
      { title: "Registration", name: "registration", linkTo: "/agreement/{registration}", className: "w-10" },
      { title: "Partner", name: "partnerRegistration", linkTo: "/person/{partnerRegistration}", className: "w-10" },
      { title: "Name", name: "name" },
      { title: "Organization", name: "organization.name", hidden: true },
      { title: "Status", name: "status", select: "agreementStatus", badge: true, className: "w-10" },
      { title: "Type", name: "type", select: "agreementType", className: "w-10" },
      { title: "Start date", name: "startDate", type: "date", className: "w-10" },
      { title: "End date", name: "endDate", type: "date", className: "w-10" },
    ]

    this.state = {
      entityName: "Agreement",
      agreements: [],
      pageConfig,
      columns: mergeColumns(defaultColumns, pageConfig?.columns),
      pinnedData: [],
      paginationParams: null,
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    this.getAgreements()
  }

  componentDidUpdate(prevProps) {
    if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) {
      this.getAgreements()
    }
  }

  getAgreements = async ({ params = {}, isAdvancedSearch, nextPagination } = {}) => {
    const { location, history } = this.props
    const { columns, pageConfig, entityName, agreements: stateEntities } = this.state
    const { search, pathname } = location
    const queryParams = { ...searchParamToObject(search), ...params }
    const getEntitiesQueryParams = { ...pageConfig?.defaultQuery, ...pruneObject(queryParams), ...pageConfig?.query }
    if (columns.find(it => it.name === "organization.name" && !it.hidden)) {
      getEntitiesQueryParams.include = "organizationName"
    }

    this.setState({ loading: true })

    if (nextPagination) {
      const { data: moreEntities, paginationParams } = await getEntities(entityName, getEntitiesQueryParams, { nextPagination })
      this.setState({ agreements: [...stateEntities, ...moreEntities], paginationParams, loading: false })
      this.preventAutoRefresh.current = true
      delete queryParams.range
      history.replace(`${pathname}${toQueryString(queryParams)}`)
      this.preventAutoRefresh.current = false
      return
    }

    const [{ data: agreements, paginationParams }, pinnedData] = await Promise.all([
      getEntities(entityName, getEntitiesQueryParams),
      fetchPinnedEntities({ entityName, queryParams: getEntitiesQueryParams }),
    ])

    this.setState({ agreements, paginationParams, pinnedData, loading: false })

    if (params) {
      this.preventAutoRefresh.current = true
      if (isAdvancedSearch) queryParams.page = 1
      history.replace(`${pathname}${mergeQueryParams(search, queryParams)}`)
      this.preventAutoRefresh.current = false
    }
  }

  handleNewPartnerModalClose = isSave => {
    this.setState({ showNewPartnerModal: false })
    if (isSave) this.getAgreements()
  }

  render() {
    const { agreements, loading, showNewPartnerModal, pageConfig, columns, entityName, pinnedData, paginationParams } = this.state
    const { location, history } = this.props

    const title = loc(pageConfig?.title || "Agreements")
    const queryParams = searchParamToObject(location.search)

    const { filter = "" } = queryParams
    const filteredAgreements = filter?.trim() ? filterAgreements(agreements, filter) : agreements

    const action = (
      <>
        <CustomButton
          fill
          pullRight
          bsSize="small"
          bsStyle="primary"
          hidden={pageConfig?.addButton?.hidden}
          className="inline-flex-center"
          onClick={() => history.push(pageConfig?.addButton?.linkTo || "/agreement")}
        >
          <i className="icn-plus icn-xs mr-5px" />
          {loc(pageConfig?.addButton?.label || "Add")}
        </CustomButton>
        {!pageConfig?.hideOnboardButton && (
          <OverlayTrigger overlay={<Tooltip id="errors">{loc`Send a self registration email to a new partner`}</Tooltip>}>
            <CustomButton
              bsStyle="primary"
              bsSize="small"
              className="inline-flex-center"
              pullRight
              onClick={() => this.setState({ showNewPartnerModal: true })}
            >
              <i className="icn-envelope icn-xs mr-5px" />
              {loc`Onboard a new partner`}
            </CustomButton>
          </OverlayTrigger>
        )}
      </>
    )

    return (
      <Grid className="agreements-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard noCard rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "agreements-kpi", noCard: false } }]} />
        )}

        <Card title={title} action={action}>
          <Table
            useSearchRowComponent
            exportDataFileName={title}
            getEntities={this.getAgreements}
            searchFields={agreementSearchFields}
            showRowsCount
            columns={columns}
            data={filteredAgreements}
            entityName={entityName}
            pinnedData={pinnedData}
            loading={loading}
            pageConfig={pageConfig}
            paginationParams={paginationParams}
          />
        </Card>

        {showNewPartnerModal && (
          <Suspense fallback={true}>
            <NewPartnerModal onClose={this.handleNewPartnerModalClose} />
          </Suspense>
        )}
      </Grid>
    )
  }
}

export default AgreementsPage

export const agreementSearchFields = [
  [
    { field: "trigram", colProps: { xs: 12, sm: 12, md: 4 } },
    { field: "name", colProps: { xs: 12, sm: 12, md: 8 } },
  ],
  [
    {
      field: "partnerRegistration",
      label: "Partner",
      searchEntityName: "Person",
      placeholder: "Type to search for a partner",
      query: { role: ["PARTNER", "BUSINESSUNIT", "AGENCY"] },
      actionHidden: true,
      defaultOptions: true,
      colProps: { xs: 12, sm: 12, md: 4 },
    },
    { field: "type", type: "multiple", select: "agreementType", colProps: { xs: 12, sm: 12, md: 8 } },
  ],
  [
    { field: "status", select: "agreementStatus", type: "multiple", colProps: { xs: 12, sm: 12, md: 4 } },
    { field: "startDate", type: "date", colProps: { xs: 12, sm: 12, md: 4 } },
    { field: "endDate", type: "date", colProps: { xs: 12, sm: 12, md: 4 } },
  ],
]

function filterAgreements(agreements = [], filter) {
  if (!filter) return agreements
  const includes = getFilterFunction(filter, getLocale())

  return agreements.filter(agreement => {
    const typeLabel = getLabel("agreementType", agreement.type)
    const statusLabel = getLabel("agreementStatus", agreement.status)

    return (
      includes(typeLabel) ||
      includes(statusLabel) ||
      includes(agreement.type) ||
      includes(agreement.name) ||
      includes(agreement.status) ||
      includes(agreement.endDate) ||
      includes(agreement.startDate) ||
      includes(agreement.description) ||
      includes(agreement.registration) ||
      includes(getEntityDisplay(agreement)) ||
      includes(agreement.partnerRegistration)
    )
  })
}
